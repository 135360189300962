// extracted by mini-css-extract-plugin
var _1 = "rgb(229.602739726, 65.5787671233, 48.647260274)";
var _2 = "#adb5bd";
var _3 = "rgb(93.625, 176.015, 11.235)";
var _4 = "rgb(41.1705020921, 146.5052301255, 217.0794979079)";
var _5 = "rgb(249.25, 186.9375, 0)";
var _6 = "rgb(104.9750996016, 60.8266932271, 185.4233067729)";
var _7 = "rgb(252.8924050633, 119.0601265823, 7.3575949367)";
var _8 = "rgb(229.602739726, 65.5787671233, 48.647260274)";
var _9 = "#374854";
var _a = "#374854";
var _b = "rgb(93.625, 176.015, 11.235)";
export { _1 as "arrow", _2 as "color0", _3 as "color1", _4 as "color2", _5 as "color3", _6 as "color4", _7 as "color5", _8 as "danger", _9 as "innerBorder", _a as "outerBorder", _b as "success" }
