// extracted by mini-css-extract-plugin
var _1 = "caption-top";
var _2 = "noBorders";
var _3 = "table";
var _4 = "table-active";
var _5 = "table-bordered";
var _6 = "table-borderless";
var _7 = "table-danger";
var _8 = "table-dark";
var _9 = "table-group-divider";
var _a = "table-hover";
var _b = "table-info";
var _c = "table-light";
var _d = "table-primary";
var _e = "table-responsive";
var _f = "table-responsive-lg";
var _10 = "table-responsive-md";
var _11 = "table-responsive-sm";
var _12 = "table-responsive-xl";
var _13 = "table-responsive-xxl";
var _14 = "table-secondary";
var _15 = "table-sm";
var _16 = "table-striped";
var _17 = "table-striped-columns";
var _18 = "table-success";
var _19 = "table-warning";
export { _1 as "caption-top", _2 as "noBorders", _3 as "table", _4 as "table-active", _5 as "table-bordered", _6 as "table-borderless", _7 as "table-danger", _8 as "table-dark", _9 as "table-group-divider", _a as "table-hover", _b as "table-info", _c as "table-light", _d as "table-primary", _e as "table-responsive", _f as "table-responsive-lg", _10 as "table-responsive-md", _11 as "table-responsive-sm", _12 as "table-responsive-xl", _13 as "table-responsive-xxl", _14 as "table-secondary", _15 as "table-sm", _16 as "table-striped", _17 as "table-striped-columns", _18 as "table-success", _19 as "table-warning" }
