// extracted by mini-css-extract-plugin
var _1 = "#e74c3c";
var _2 = "#adb5bd";
var _3 = "#64bc0c";
var _4 = "#3498db";
var _5 = "#ffc107";
var _6 = "#6f42c1";
var _7 = "#fd7e14";
var _8 = "#e74c3c";
var _9 = "#dee2e6";
var _a = "#dee2e6";
var _b = "#64bc0c";
export { _1 as "arrow", _2 as "color0", _3 as "color1", _4 as "color2", _5 as "color3", _6 as "color4", _7 as "color5", _8 as "danger", _9 as "innerBorder", _a as "outerBorder", _b as "success" }
