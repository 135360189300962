// extracted by mini-css-extract-plugin
var _1 = "m0SzF__message";
var _2 = "m0SzF__messageEntry";
var _3 = "m0SzF__message_bot";
var _4 = "m0SzF__message_button";
var _5 = "m0SzF__time";
var _6 = "m0SzF__tooltip";
var _7 = "m0SzF__user";
var _8 = "m0SzF__username";
var _9 = "m0SzF__username_friend";
export { _1 as "message", _2 as "messageEntry", _3 as "message_bot", _4 as "message_button", _5 as "time", _6 as "tooltip", _7 as "user", _8 as "username", _9 as "username_friend" }
