// extracted by mini-css-extract-plugin
var _1 = "vAauw__image";
var _2 = "vAauw__result";
var _3 = "vAauw__resultSlider";
var _4 = "vAauw__resultSlider_hidden";
var _5 = "vAauw__resultSlider_visible";
var _6 = "vAauw__resultWrapper";
var _7 = "vAauw__resultWrapper_hidden";
var _8 = "vAauw__result_lost";
var _9 = "vAauw__result_won";
export { _1 as "image", _2 as "result", _3 as "resultSlider", _4 as "resultSlider_hidden", _5 as "resultSlider_visible", _6 as "resultWrapper", _7 as "resultWrapper_hidden", _8 as "result_lost", _9 as "result_won" }
