// extracted by mini-css-extract-plugin
var _1 = "icon";
var _2 = "icon-blue";
var _3 = "icon-cyan";
var _4 = "icon-danger";
var _5 = "icon-dark";
var _6 = "icon-gray";
var _7 = "icon-gray-dark";
var _8 = "icon-green";
var _9 = "icon-indigo";
var _a = "icon-info";
var _b = "icon-light";
var _c = "icon-orange";
var _d = "icon-pink";
var _e = "icon-primary";
var _f = "icon-purple";
var _10 = "icon-red";
var _11 = "icon-secondary";
var _12 = "icon-success";
var _13 = "icon-teal";
var _14 = "icon-warning";
var _15 = "icon-white";
var _16 = "icon-yellow";
export { _1 as "icon", _2 as "icon-blue", _3 as "icon-cyan", _4 as "icon-danger", _5 as "icon-dark", _6 as "icon-gray", _7 as "icon-gray-dark", _8 as "icon-green", _9 as "icon-indigo", _a as "icon-info", _b as "icon-light", _c as "icon-orange", _d as "icon-pink", _e as "icon-primary", _f as "icon-purple", _10 as "icon-red", _11 as "icon-secondary", _12 as "icon-success", _13 as "icon-teal", _14 as "icon-warning", _15 as "icon-white", _16 as "icon-yellow" }
