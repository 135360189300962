// extracted by mini-css-extract-plugin
var _1 = "mBWkI__center";
var _2 = "mBWkI__entries";
var _3 = "mBWkI__gameLink";
var _4 = "mBWkI__gameType";
var _5 = "mBWkI__head";
var _6 = "mBWkI__infoButton";
var _7 = "mBWkI__profit";
var _8 = "mBWkI__profitHeader";
var _9 = "mBWkI__slide1";
var _a = "mBWkI__slide2";
var _b = "mBWkI__slideIn";
var _c = "mBWkI__table";
var _d = "mBWkI__tdGameType";
var _e = "mBWkI__userButton";
export { _1 as "center", _2 as "entries", _3 as "gameLink", _4 as "gameType", _5 as "head", _6 as "infoButton", _7 as "profit", _8 as "profitHeader", _9 as "slide1", _a as "slide2", _b as "slideIn", _c as "table", _d as "tdGameType", _e as "userButton" }
